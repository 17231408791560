const wordByCategory: {
  [category: string]: [string, string][];
} = {
  food: [
    ["回転寿司や", "ファミリーレストラン"],
    ["フルーツ牛乳", "コーヒー牛乳"],
    ["カキフライ", "エビフライ"],
    ["ドーナツ", "バウムクーヘン"],
    ["カルピス", "牛乳"],
    ["マクドナルド", "モスバーガー"],
    ["キムチ鍋", "麻婆豆腐"],
    ["ハンバーガー", "サンドウィッチ"],
    ["カルボナーラ", "グラタン"],
    ["ケーキ", "パフェ"],
    ["ラーメン屋", "カレー屋"],
    ["野菜ジュース", "豆乳"],
    ["ハンバーグ", "コロッケ"],
    ["タルト", "マカロン"],
    ["納豆", "にんにく"],
    ["クリームパン", "シュークリーム"],
    ["ステーキ", "ローストビーフ"],
    ["喫茶店", "ファミリーレストラン"],
    ["味噌カツ", "エビフライ"],
    ["アサリ", "ホタテ"],
    ["海鮮丼", "うな丼"],
    ["ファミチキ", "からあげクン"],
    ["マンゴープリン", "杏仁豆腐"],
    ["ウーロン茶", "コーラ"],
    ["ピーナッツ", "アーモンド"],
    ["ジャム", "マーガリン"],
    ["グラタン", "カルボナーラ"],
    ["ソース", "焼肉のたれ"],
    ["はちみつ", "練乳"],
    ["パフェ", "かき氷"],
    ["りんごジュース", "オレンジジュース"],
    ["松茸", "スッポン"],
    ["苦味", "酸味"],
    ["ヤクルト", "カルピス"],
    ["たい焼き", "チーズケーキ"],
    ["ジンジャーエール", "紅茶"]
  ],
  love: [
    ["メンヘラ", "ツンデレ"],
    ["包容力がある人", "嘘をつかない人"],
    ["高学歴な男性", "イケメン"],
    ["居酒屋", "Bar"],
    [
      "熱くない食べ物を「ふぅーふぅー」する人",
      "スパゲッティを音を立ててすする人"
    ],
    ["三角関係", "片思い"],
    ["骨が折れている恋人", "風邪をひいている恋人"],
    ["ラブレター", "果たし状"],
    ["ファッション雑誌", "少女漫画"],
    ["一番最近付き合っていた人のいいところ", "右どなりの人のいいところ"],
    ["社内恋愛", "先生と生徒の恋愛"],
    ["清潔感のない異性", "嫌いな人に顔が似ている異性"],
    ["好きなアーティストのTシャツ", "好きなアーティストのポスター"],
    ["キスされる", "告白される"],
    ["手をつなぐ（恋愛）", "デート（恋愛）"],
    ["幼馴染を好きになってしまう", "恋人がいる人を好きになってしまう"],
    ["恋人に浮気される", "恋人に振られる"],
    ["ガラケー", "FAX"],
    ["風邪をひいた時に看病してくれる", "愚痴を聞いてくれる"],
    ["説教", "セクハラ"],
    ["初めてのキス", "初めてハンバーガーを食べた"],
    ["笑う時に口に手を当てる異性", "笑顔であいづちを打ってくれる異性"],
    ["恋人といちゃいちゃする", "家族と仲良く遊ぶ"],
    ["黒歴史を思い出した時の辛さ", "失恋の辛さ"],
    ["お見合い", "婚活パーティー"],
    ["金持ちな異性", "料理が得意な異性"],
    ["出会い系アプリで結婚", "お見合いで結婚"],
    ["オーバーリアクションな異性", "キメ顔がワンパターンな異性"],
    ["電話で別れ話", "LINEで告白"],
    ["ラブレターをもらう", "お小遣いをもらう"],
    ["ダイエット", "恋人を束縛"],
    ["選手宣誓", "告白"],
    ["ギャンブルが好きな異性", "タバコを吸う異性"],
    ["異性に頭をポンポンされる", "異性に後ろから抱きつかれる"],
    ["石油王", "白馬に乗った王子様"],
    ["野良猫が自分に近寄ってくる", "好きな人が近寄ってくる"],
    ["バク転が出来る異性", "足が速い異性"],
    ["顔が整っている異性", "脚が長い異性"],
    ["カフェ店員の異性", "アパレル店員の異性"],
    ["花火", "夜景"],
    ["ナンパ", "一目惚れ"],
    ["マッチョ", "ナルシスト"],
    ["目の下にほくろがある異性", "えくぼが特徴の異性"],
    ["だらしない体の異性", "正確が悪い異性"],
    ["理想の仕事", "理想の彼氏・彼女"],
    ["説教好き", "ドS"],
    ["毎日挨拶をしてくれる異性", "自分と喋ったことをすべて覚えている異性"],
    ["顔が良いのに整形したいという人", "太ってないのに痩せたいという人"],
    ["嫉妬（恋愛）", "浮気（恋愛）"],
    ["好きな人から告白される", "ファーストキス"],
    ["帰り道にに手をつなぐ", "教室でキス"],
    ["顔が好みな異性", "スポーツ万能な異性"],
    ["好きな人を1人に決められない人", "出会いが無い人"],
    ["失恋", "片思い"],
    ["既読スルーされる", "イジられる（ちょっかいを出される）"],
    ["ストーカー", "探偵"],
    ["両親の過去の恋愛事情", "恋人の過去の恋愛事情"],
    ["初めてのキス", "初恋"],
    ["恋人の箸の持ち方が変", "恋人が敬語をしゃべれない"],
    ["癖（くせ）", "フェチ"],
    ["ケチな異性", "嘘つきな異性"],
    ["年齢詐称", "結婚詐欺"],
    ["かたぐるま", "抱っこ"],
    ["別れた恋人との写真", "卒業アルバム"],
    ["ゲームが上手い異性", "自撮りが得意な異性"],
    ["化粧", "自分磨き"],
    ["外国かぶれ（外国大好き）", "サブカル人間（サブカルチャー大好き）"],
    ["指先がセクシーな異性", "くちびるがセクシーな異性"],
    ["ケンカ", "マンネリ"],
    ["同じ服ばかり着ている異性", "ボロボロの古着を着ている異性"],
    ["くしゃみの時に色っぽい声を出す異性", "髪をかきわける異性のしぐさ"],
    ["ぶりっ子", "思わせぶりな態度"]
  ],
  life: [
    ["飛行機", "長距離バス"],
    ["蛾（が）", "蜂（はち）"],
    ["僧侶（僧侶）", "医者（いしゃ）"],
    ["お坊さん", "神父"],
    ["バレンタインデー", "クリスマス"],
    ["徹夜", "昼寝"],
    ["こたつ", "お風呂"],
    ["電車", "バス"],
    ["蛸（タコ）", "海鼠（ナマコ）"],
    ["かき氷を食べて頭が痛い", "アルミホイルを噛んでしまって頭が痛い"],
    ["恐竜", "ゾウ"],
    ["親友", "家族"],
    ["空気清浄機", "掃除機"],
    ["斧（おの）", "鋸（のこぎり）"],
    ["お月見", "餅つき"],
    ["船長", "王子"],
    ["国籍", "住所"],
    ["メジャーリーガー", "宇宙飛行士"],
    ["虫歯", "腰痛"],
    ["口内炎", "ニキビ"],
    ["トマトジュース", "血"],
    ["やかん", "電子レンジ"],
    ["徹夜", "空腹"],
    ["ハサミ", "包丁"],
    ["パンツ", "財布"],
    ["セーラー服", "メイド服"],
    ["空手", "テコンドー"],
    ["兄弟", "同級生"],
    ["セグウェイ", "キックボード"],
    ["脅迫状", "呪い"],
    ["いとこ", "おばあちゃん"],
    ["靴下", "帽子"],
    ["ヒゲ", "モミアゲ"],
    ["ブーメラン", "槍（やり）"],
    ["おでこ", "くちびる"],
    ["真っ暗な部屋", "すごく狭い部屋"],
    ["電車の運転手", "飛行機のパイロット"],
    ["車酔い", "二日酔い"],
    ["梅雨", "台風"],
    ["殺虫剤", "消臭剤"],
    ["法廷", "警察署"],
    ["ねごと", "はぎしり"],
    ["性欲", "食欲"],
    ["毒薬", "毒リンゴ"],
    ["タイヤ", "ハンドル"],
    ["魔女", "武士"],
    ["円周率", "元素記号"],
    ["ボディーソープで髪を洗う", "洗顔クリームで歯を磨く"],
    ["冷静", "情熱"],
    ["ゴミ屋敷（ごみやしき）", "喫煙所（きつえんじょ）"],
    ["千利休", "一休さん"],
    ["ドジ", "ポジティブ"],
    ["理想の恋人の職業", "自分が就きたい理想の職業"],
    ["心理テスト", "占い"],
    ["オオカミ", "イヌ"],
    ["仮想通貨", "AI"],
    ["ガードレール", "横断歩道"],
    ["耳栓（みみせん）", "アイマスク"],
    ["輪ゴム", "ガムテープ"],
    ["ベートーヴェン", "モーツァルト"],
    ["山", "階段"],
    ["サービス残業", "ボランティア"],
    ["レーザー脱毛", "豊胸手術"],
    ["コンタクトレンズ", "レーシック"],
    ["蜘蛛（くも）", "蛇（へび）"],
    ["ティッシュ", "ハンカチ"],
    ["飛行機のパイロット", "タクシー運転手"],
    ["ネクタイ", "ネックレス"],
    ["ダイエット", "節約"],
    ["ブログ", "交換日記"]
  ],
  play: [
    ["「3分間自己紹介して」と言われる", "「すべらない話をして」と言われる"],
    ["新婚旅行", "卒業旅行"],
    ["渡　直美", "ブルゾンちえみ"],
    ["汗（あせ）", "涎（よだれ）"],
    ["赤ちゃんの世話", "ペットの飼育"],
    ["透明になれる能力を持つ", "時間を止められる能力を持つ"],
    ["フリスビー", "水鉄砲"],
    ["E-Girls", "EXILE"],
    ["幽霊", "ゾンビ"],
    ["ゲートボール", "ゴルフ"],
    ["志村けん", "ビートたけし"],
    ["ジェットコースター", "新幹線"],
    ["3ポイントシュート（バスケット）", "満塁ホームラン"],
    ["ねんど", "砂遊び"],
    ["西郷隆盛", "坂本龍馬"],
    ["乗馬", "ゴルフ"],
    ["柔道", "相撲"],
    ["スカート", "マント"],
    ["ツチノコ", "宇宙人"],
    ["サーフィン", "バナナボート"],
    ["博物館", "動物園"],
    ["スノーボード", "そり"],
    ["妖怪ウォッチ", "ポケットモンスター"],
    ["ドラえもん", "名探偵コナン"],
    ["バイク", "ゴーカート"],
    ["野原みさえ（クレヨンしんちゃん）", "磯野フネ（サザエさん）"],
    ["サザエさん", "ちびまる子ちゃん"],
    ["プール", "海"],
    ["誕生日", "元旦"],
    ["ドッジボール", "鬼ごっこ"],
    ["オセロ", "将棋"],
    ["魔法", "超能力"],
    ["ホラー映画", "ミュージカル映画"],
    ["好きな先生", "憧れの先輩"],
    ["ピカチュウ", "ミッキーマウス"],
    ["トランペット", "リコーダー"],
    ["キョロちゃん", "ペコちゃん"],
    ["明石家さんま", "タモリ"],
    ["沖縄", "ハワイ"],
    ["アニメ", "ドラマ"],
    ["吸血鬼", "蚊（か）"],
    ["カラオケ", "合唱"],
    ["遊園地", "サファリパーク"],
    ["水風船", "フーセンガム"],
    ["ゴルフ場", "バッティングセンター"],
    ["アキラ100％（芸人）", "とにかく明るい安村（芸人）"],
    ["シェンロン（ドラゴンボール）", "魔法のランプ（アラジン）"],
    ["チェス", "カジノ"],
    ["トムとジェリー", "セサミストリート"],
    ["地図なしで目的地へ行く", "土砂降りの中目的地へ行く"],
    ["ミニオン", "スティッチ"],
    ["サーフィン", "スノーボード"],
    ["白雪姫", "シンデレラ"],
    ["大喜利（おおぎり）", "一発ギャグ"],
    ["スイカの種飛ばし", "石で水切り"],
    ["バックトゥザフューチャー", "スターウォーズ"],
    ["跳び箱", "なわとび"],
    ["岩盤浴", "足湯"],
    ["海水浴", "山登り"],
    ["テニス", "卓球"],
    ["プラネタリウム", "映画館"],
    ["水風船", "水鉄砲"],
    ["手拍子", "口笛"],
    ["宇多田ヒカル", "浜崎あゆみ"],
    ["リラックマ", "スティッチ"],
    ["催眠術", "超能力"],
    ["関ジャニ∞", "乃木坂46"],
    ["入学試験の合格発表", "初めての告白"],
    ["ジャングル", "無人島"],
    ["食べ過ぎ", "テレビゲーム"],
    ["人工車で観光", "バスツアーで観光"],
    ["ルーレット", "パチンコ"]
  ],
  child: [
    ["しっぺ", "チョップ"],
    ["マスク", "マフラー"],
    ["おはし", "フォーク"],
    ["ピアノ", "ギター"],
    ["イチゴ", "モモ"],
    ["虫取り", "魚釣り"],
    ["くちぶえ", "ゆびぱっちん"],
    ["トマト", "リンゴ"],
    ["おこのみやき", "たこやき"],
    ["ドラゴンクエスト", "ファイナルファンタジー"],
    ["プリン", "ちゃわんむし"],
    ["オニ", "ピエロ"],
    ["砂場あそび", "粘土あそび"],
    ["だるまさんがころんだ", "ハンカチおとし"],
    ["やかん", "ポット"],
    ["オムライス", "ギョウザ"],
    ["カマキリ", "バッタ"],
    ["イヌ", "ネコ"],
    ["ピラフ", "チャーハン"],
    ["イカ", "クラゲ"],
    ["クレヨンしんちゃん", "アンパンマン"],
    ["にんじん", "たまねぎ"],
    ["バッタ", "トンボ"],
    ["きんぎょ", "ミドリガメ"],
    ["スヌーピー", "グーフィー"],
    ["ニンジン", "ダイコン"],
    ["どこでもドアを手にいれる", "タケコプターを手にいれる"],
    ["シンバル", "トライアングル"],
    ["ひやけ", "かさぶた"],
    ["おにぎり", "すし"],
    ["ピカチュウ", "ジバニャン"],
    ["プリン", "ゼリー"],
    ["マヨネーズ", "ケチャップ"],
    ["しゃっくり", "ゲップ"],
    ["くしゃみ", "ためいき"],
    ["にら", "ねぎ"],
    ["雛人形", "こいのぼり"],
    ["マフラー", "マスク"],
    ["ミニーマウス", "ハローキティ"],
    ["ドーナツ", "だんご"],
    ["ビーズ", "レゴ"],
    ["たんにんのせんせい", "おかあさん"],
    ["サンドイッチ", "ドーナツ"],
    ["クロスワードパズル", "オセロ"],
    ["けんだま", "あやとり"],
    ["ゴミばこ", "タンス"],
    ["ヘルメット", "かつら"],
    ["ぎょうざ", "たこやき"],
    ["スリッパ", "サンダル"],
    ["ソフトボール", "ドッジボール"],
    ["おままごと", "ヒーローごっこ"],
    ["ムーミン", "きかんしゃトーマス"],
    ["ケーキ", "アイス"],
    ["ハエ", "ゴキブリ"],
    ["おこづかい", "おつり"],
    ["しまじろう", "くまのプーさん"],
    ["チーズ", "シロ"],
    ["ゴーヤー", "ヘチマ"],
    ["しょうゆ", "ソース"],
    ["あくび", "くしゃみ"],
    ["ろうそく", "ランプ"],
    ["ふりかけ", "うめぼし"],
    ["クロワッサン", "フランスパン"],
    ["ブランコ", "すべりだい"]
  ]
};

export const words: {
  [category: string]: [string, string][];
} = {
  all: Object.values(wordByCategory).reduce((pre, cur) => {
    pre.push(...cur);
    return pre;
  }, []),
  ...wordByCategory
};

export const wordCategory = ["all", ...Object.keys(wordByCategory)];
